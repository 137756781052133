<template>
  <div id="abc" class="select-main">
    <div class="div-main">
    <loading :loading="loading"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="任务ID" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="id" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="任务名称" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="text" v-model="name" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="任务类型" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select  v-model="categoryId" placeholder="请选择">
            <el-option
                v-for="item in categorys"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分校" style="width: 300px; margin-bottom:10px;display: inline-block">
          <branch-select ref="branchSelect"></branch-select>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divList">
        <div class="opt">
          <el-button type="primary" size="small" @click="handleClickAdd">添加</el-button>
          <downloadexcel
              id               ="exportExcel"
              class            = "export"
              name             = "打卡明细"
              :fetch           = "fetchData"
              :fields          = "excelFields"
              :before-generate = "startDownload"
              :before-finish   = "finishDownload"></downloadexcel>
        </div>
        <el-table id="tblList" border :data="tasks" style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
          <el-table-column prop="id" label="ID" align="center" width="100"></el-table-column>
          <el-table-column prop="name" label="任务名称" align="left"></el-table-column>
          <el-table-column prop="integral" label="奖励积分" align="center" width="100"></el-table-column>
          <el-table-column prop="categoryName" label="任务分类" align="center" width="100"></el-table-column>
          <el-table-column prop="typeName" label="任务类型" align="center" width="100"></el-table-column>
          <el-table-column prop="sort" label="排序" align="center" width="100"></el-table-column>
          <el-table-column prop="createdOn" label="创建日期" align="center" width="200"></el-table-column>
          <el-table-column fixed="right" label="操作" width="220" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickDetail(scope.row)" type="text"   size="small">查看明细</el-button>
              <el-button @click="handleClickExport(scope.row)" type="text"  size="small">导出明细</el-button>
              <el-button @click="handleClickEdit(scope.row)" type="text"   size="small">编辑</el-button>
              <el-button @click="handleClickDel(scope.row)" type="text"   size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page="page" :size="size" @pageChange="handleCurrentChange" @sizeChange="handleSizeChange"></pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import {
  POP_HEIGHT_SMALL,
  POP_WIDTH_MIDDLE,
  UrlEnum,
  TaskCategoryEnum,
  getLocalProjectId,
  POP_WIDTH_BIG,
  POP_HEIGHT_BIG, TipEnum,TaskTypeEnum
} from "../../../../public/js/common-vue";
import Pagination from "@/components/Pagination";
import EditDialog from "@/components/EditDialog";
import downloadexcel from "vue-json-excel";
import Loading from "@/components/Loading";
import BranchSelect from "../../../components/BranchSelect";

export default {
  name: "TaskList",
  data() {
    return {
      loading: false,
      tableShow:false,
      id:'',
      name:'',
      categoryId:'',
      total:0,
      page:1,
      size:20,
      tasks:[],
      excelFields:{},
      exportUrl:'',
      categorys:[
        {id:TaskCategoryEnum.RI_CHANG,name:"日常任务"},
        {id:TaskCategoryEnum.DU_XUE,name: "督学任务"},
        {id:TaskCategoryEnum.MATCH,name: "挑战赛"},
        {id:TaskCategoryEnum.MRYL,name: "每日一练"}
      ]
    }
  },
  components:{Loading, Pagination,EditDialog,downloadexcel,BranchSelect},
  methods:{
    async fetchData(){
      const response = await this.$http.get(this.exportUrl);
      const data = response.data.list;
      console.log(JSON.stringify(data));
      for (let i = 0; i < data.length; i++) {
        data[i].studentId = (data[i].studentId).toString();
      }
      if(data.length == 0){
        this.showMsgError("记录为空")
        //记录为空时不会执行finishDownload()
        this.loading = false;
      }
      return data;
    },
    startDownload(){
      this.loading=true;
    },
    finishDownload(){
      this.loading=false;
    },
    handleClickAdd(){
      var url = 'save?'+new Date();
      this.$refs.editDialog.init('edit',POP_WIDTH_BIG,POP_HEIGHT_BIG,'添加任务',url);
    },
    handleClickEdit(row){
      this.$refs.editDialog.init('edit',POP_WIDTH_BIG,POP_HEIGHT_BIG,'编辑任务','save?id='+row.id);
    },
    handleClickExport(row){
      let taskId = row.id;
      let type = row.type;
      let categoryId = row.categoryId;
      if(
          (
              type == TaskTypeEnum.KE_GUAN_TI ||
              type == TaskTypeEnum.ZHU_GUAN_TI ||
              type == TaskTypeEnum.ZUO_YE_KG ||
              type == TaskTypeEnum.ZUO_YE_ZG
          )
          &&
          (categoryId == TaskCategoryEnum.DU_XUE)
      ){
        this.exportUrl = UrlEnum.STUDENT_INTEGRAL_TASK + "/exam-records?orderBy=id&orderType=asc&page=1&size=1000&taskId=" + taskId;
        this.excelFields = {
          '学员ID':'studentId',
          '昵称':'nickname',
          '手机号':'username',
          '获得积分':'integral',
          '积分余额':'balance',
          '任务名称':'name',
          '正确率':'accuracy',
          '完成时间':'createdOn'
        }
      }
      else{
        this.exportUrl = UrlEnum.STUDENT_INTEGRAL_TASK + "/common-records?orderBy=id&orderType=asc&page=1&size=10000&taskId=" + taskId;
        this.excelFields = {
          '学员ID':'studentId',
          '昵称':'nickname',
          '手机号':'username',
          '获得积分':'integral',
          '积分余额':'balance',
          '任务名称':'name',
          '完成时间':'createdOn'
        }
      }
      document.getElementById("exportExcel").click();
    },
    handleClickDetail(data){
      let type = data.type;
      let categoryId = data.categoryId;
      if(
          (type == TaskTypeEnum.KE_GUAN_TI || type == TaskTypeEnum.ZHU_GUAN_TI || type == TaskTypeEnum.ZUO_YE_KG || type == TaskTypeEnum.ZUO_YE_ZG || type == TaskTypeEnum.PAN_DUAN || type == TaskTypeEnum.TIAN_KONG) &&
          (categoryId == TaskCategoryEnum.DU_XUE || categoryId == TaskCategoryEnum.MATCH || categoryId == TaskCategoryEnum.MRYL)
      ){
        this.$refs.editDialog.init('detail',"80%",POP_HEIGHT_BIG,'任务明细','detail-exam?id='+data.id+'&type='+type+'&categoryId='+categoryId);
      }
      else{
        this.$refs.editDialog.init('detail',"80%",POP_HEIGHT_BIG,'任务明细',"detail?id="+data.id+"&type="+type+"&categoryId="+categoryId)
      }
    },
    handleClickDel(row){
      this.$confirm('确定删除此信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(row.id);
      }).catch(() => {
        console.log('catch')
      });
    },
    search(){
      this.page=1;
      this.getData();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.INTEGRAL_TASKS;
      url += "?page="+this.page+"&size="+this.size+"&projectId="+getLocalProjectId();
      if(this.id != ''){
        url += "&id="+this.id;
      }
      if(this.name != ''){
        url += "&name="+this.name;
      }
      if(this.categoryId != ''){
        url += "&categoryId="+this.categoryId;
      }
      let branchId = this.$refs.branchSelect.getBranchId();
      if(branchId != ''){
        url += "&branchId="+ branchId;
      }
      return url;
    },
    getData() {
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        this.tasks = res.data.list;
        this.tableShow=true;
       })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
    reset(){
      this.page=1;
      this.id='';
      this.name='';
      this.categoryId='';
      this.$refs.branchSelect.setBranchId('');
      this.getData();
    },
    del(id){
      this.loading = true;
      const url = UrlEnum.INTEGRAL_TASKS+'/'+id;
      this.$http({
        method: "delete",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.DELETE_SUCCESS);
        this.getData();
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
  },
  mounted() {
    this.getData();
    this.$refs.branchSelect.initData(null);
    var that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.export{
  display:none;
}
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divList{
  margin:10px;
}
.opt{
  height: 40px;
}
</style>
